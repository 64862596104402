<template>
    <div>
        
    </div>
</template>

<script>
import store from "./store/index"

export default {
    created() {
        if (!this.$store.hasModule("files"))
            this.$store.registerModule("files", store);
    }
}
</script>